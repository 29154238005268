function addCccConfigMark (obj, cccConfig, cccType = 'productType') {
  cccConfig.displayContent && (obj.cccConfig = { cccType, ...cccConfig })
  obj.label = cccConfig?.displayContent?.labelLang || obj?.label || obj?.tag_name || ''
  
  return obj
}

export function handleCloudTagsByProductTypeSort ({
  cloudTags = [],
  productTypeSortConfig = [],
  filterType = 'isExposed'
}) {
  if (!cloudTags.length) return cloudTags
  // 防止后端返回数据排序变化，前端根据tagid排序
  const toNumber = (str) => {
    const match = String(str)?.match(/\d+/g) ?? ''
    if (!match) return 0

    return match?.join('')
  }

  const sortCloudTags = cloudTags.sort((tag1, tag2) => {
    if (tag1.tag_type !== tag2.tag_type) return false
    return toNumber(tag1.tag_id) - toNumber(tag2.tag_id)
  })
  
  if (!productTypeSortConfig.length) return sortCloudTags

  // 标签云外露接ccc配置
  const map = {}
  const list = []
  sortCloudTags.forEach((item) => (map[item.tag_id] = item))

  productTypeSortConfig
    .filter(item => Number(item[filterType]))
    .forEach(item => {
      switch(item.tagType) {
        // mall
        case 11:
          list.push(...sortCloudTags.filter(obj => obj.type == 1).map(obj => addCccConfigMark(obj, item)))
          break
        // qs
        case 12:
          list.push(...sortCloudTags.filter(obj => obj.type == 2).map(obj => addCccConfigMark(obj, item)))
          break
        // 促销
        case 13:
          list.push(...sortCloudTags.filter(obj => obj.tag_type == 100).map(obj => addCccConfigMark(obj, item)))
          break
        //  其他标签
        default:
          item.tagId && map[item.tagId] && list.push(addCccConfigMark(map[item.tagId], item))
          break
      }
    })

  return list
}

// 根据tagCloudSlotConfig 查找对应的tag的索引
function getTagIndexByTagCloudSlot (cloudTags, slotConfig) {
  const { tagId } = slotConfig
  let index = cloudTags.findIndex(obj => obj.value == tagId)
  if (index > -1) return index
  if(tagId == 14002) return cloudTags.findIndex(obj => obj.dataType == 'cate') // category
  if (tagId == 14003) return cloudTags.findIndex(obj => obj.dataType == 'price') // price
    
  
}
export function handleCloudTagsByTagCloudSlot ({
  cloudTags = [],
  tagCloudSlotConfig = [],
}) {
  if (!tagCloudSlotConfig.length) return cloudTags

  
  const fixTags = cloudTags.filter(tag => tag.type == 1 || tag.type == 2 || tag.dataType == 'daily') // qs/mall/date 固定最前面
  const sortTags = cloudTags.filter(tag => tag.type != 1 && tag.type != 2 && tag.dataType != 'daily') // 进行插坑的元素
  const minusNum = fixTags.length

  // 过滤不存在的tag
  const configList = tagCloudSlotConfig.filter(item => getTagIndexByTagCloudSlot(sortTags, item) > -1)

  // 处理插坑值，不允许出现负数&不能出现相同数值
  let slotList = configList
    .map(item => Math.max(0, item.pitValue - minusNum - 1))
    .map((num, index, arr) => {
      if (index === 0) return num
      const prev = arr[index - 1]
      if (prev < num) return num

      return prev + 1
    })

  configList.forEach((item, i) => {
    const slotNum = slotList[i]
    const tagIndex = getTagIndexByTagCloudSlot(sortTags, item)
    if (tagIndex < 0) return
    const tag = sortTags[tagIndex]
    item.displayContent && (tag.slotConfig = item)
    addCccConfigMark(tag, item, 'slot')
    sortTags.splice(tagIndex, 1)
    sortTags.splice(slotNum, 0, tag)
  })

  return [...fixTags, ...sortTags]
}
