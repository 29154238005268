var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tab__container",class:{
    'tab__container-underline': _vm.underLine
  },style:({
    background: _vm.backgroundColor
  })},[_c(_setup.Slidetab,{attrs:{"reverse":_vm.reverse,"space-between":24},model:{value:(_setup.modelValue),callback:function ($$v) {_setup.modelValue=$$v},expression:"modelValue"}},_vm._l((_vm.tabs),function(item,index){return _c(_setup.SlidetabItem,{key:item.tabId,staticClass:"tab__item",attrs:{"slide-id":index}},[(item.tabName)?_c('span',{directives:[{name:"expose",rawName:"v-expose",value:(item.analyticData?.expose),expression:"item.analyticData?.expose"}],class:[
          {
            active: _vm.value === index,
          },
          'tab__inner',
        ],attrs:{"data-tabname":item.tabName}},[_vm._v("\n        "+_vm._s(item.tabName)+"\n      ")]):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }