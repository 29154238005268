<template>
  <div
    :class="{
      'ptn-blank__container': true,
      'ptn-blank__container-small': isSmallStatus,
    }"
  >
    <SwitchContainer
      :list="formatList"
      :is-config-active="false"
      :shape="PIC_TOP_NAV_SHAPE.CIRCLE"
      :on-change="onChange"
    />
    <div
      v-show="formatList.length && !isSmallStatus"
      class="pictn-bottom"
    ></div>
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import SwitchContainer from './slide-expand/SwitchContainer.vue'

import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import useToRealPage from 'public/src/pages/components/FilterBar/PicTopNav/utils/toRealPage.js'

const list = inject('list', {})
const locals = inject('locals', {})
// const language = inject('language', {})
const isSmallStatus = inject('isSmallStatus', false)
const catInfo = inject('catInfo', {})

const formatList = computed(() => {
  const tabItem = list.value?.[0] || {}
  const { navs = [] } = tabItem
  
  return navs.map((navItem, navIndex) => ({
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    lazyImg: locals.value.LAZY_IMG,
    isLazyLoad: navIndex > 5,
    analysisData: getPicTopNavAnalysisData({ tabItem, navItem, navIndex, catInfo })
  }))
})
const onToRealPage = useToRealPage({ list, extraParams: {
  pageName: catInfo.value.pageName,
} })
const onChange = navIndex => onToRealPage({ tabIndex: 0, navIndex })

</script>

<style lang="less" scoped>
.ptn-blank {
  &__container {
    padding: 6px 12px; // @flag-pic-padding 改之前搜一下这个标记
    padding-bottom: 0;
    background: #fff;
  }

  &__container-small {
    min-height: 1.17rem; // 设置同header高度，避免露出header底部分割线
    padding: 6px;
  }
}

.pictn-bottom {
  width: 100vw;
  margin-left: -12px; // @flag-pic-padding 改之前搜一下这个标记
  height: 4px;
  background: #f5f5f5;
  margin-top: 6px;
}
</style>
