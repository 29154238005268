<template>
  <div 
    class="tab__container" 
    :class="{
      'tab__container-underline': underLine
    }"
    :style="{
      background: backgroundColor
    }"
  >
    <Slidetab 
      v-model="modelValue" 
      :reverse="reverse" 
      :space-between="24"
    >
      <SlidetabItem
        v-for="(item, index) in tabs"
        :key="item.tabId"
        :slide-id="index"
        class="tab__item"
      >
        <span
          v-if="item.tabName"
          v-expose="item.analyticData?.expose"
          :class="[
            {
              active: value === index,
            },
            'tab__inner',
          ]"
          :data-tabname="item.tabName"
        >
          {{ item.tabName }}
        </span>
      </SlidetabItem>
    </Slidetab>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// components
import Slidetab from 'public/src/pages/common/slidetab/SlideTab.vue'
import SlidetabItem from 'public/src/pages/common/slidetab/SlidetabItem.vue'

const emits = defineEmits(['input'])
const props = defineProps({
  tabs: {
    type: Array,
    default: () => []
  },
  value: {
    type: Number,
    default: 0
  },
  reverse: {
    type: Boolean,
    default: false
  },
  underLine: {
    type: Boolean,
    default: false
  },
  backgroundColor: {
    type: String,
    default: 'transparent'
  }
})

const modelValue = computed({
  get() {
    return props.value
  },
  set(val) {
    emits('input', val)
  }
})
</script>

<script>
export default {
  name: 'PicTopNavTab'
}
</script>

<style lang="less">
.tab{
  &__container {
    position: relative;
    display: flex;
    padding: 0 0.32rem;
    height: 38px;
  }
  &__item {
    display: flex;
    align-items: center;
  }

  &__inner {
    line-height: 38px;
    font-size: 12px;
    color: #767676;
    &::before {
      display: block;
      content: attr(data-tabname);
      font-weight: bold;
      visibility: hidden;
      height: 1px;
      color: transparent;
      margin-bottom: -1px;
      overflow: hidden;
    }

    &.active {
      color: #222;
      font-weight: 600;
    }
  }
}
.tab__container-underline {
  .tab__inner.active {
    color: #000;
    font-weight: 800;
    display: block;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 80%;
      height: 2px;
      border-radius: 2px;
      background: #000;
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
