import { getQueryString } from '@shein/common-function'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import { abtservice } from 'public/src/services/abt'
import { SEARCH_TYPE_ENUM } from 'public/src/pages/common/search_words/searchConst'
import { getSearchDiff } from '../../product_app/store/modules/product_list_v2/utils'

const homeToEntityReqSearch = function(catInfo, listAbtResult) {
  const { type } = catInfo || {}
  if (type !== 'entity') return false
  const { ListServer } = listAbtResult
  const homeToRealPageAbtConfig = {
    requestSearch: false,
  }
  let ListServerTestVal = ListServer?.p?.ListServer
  if (ListServerTestVal === 'Search') {
    homeToRealPageAbtConfig.requestSearch = true
  }

  return homeToRealPageAbtConfig?.requestSearch
}

const listAbtSceneStr = ['imageLabel', 'Aod', 'ListAttrSequence', 'GoodsTitle', 'SellingPoint', 'VideoIcon', 'AttrLinkCategory', 'ListLeftFilter', 'listlabelQuickship', 'SearchLeftFilter', 'HideGoodsNum', 'PinFiltersAndLabels', 'SameLabel',  'ShowPromotion', 'UserBehaviorLabel', 'listReview', 'ShowLabelClouds', 'ListLoadmore', 'MostPopular', 'ListMostPopular', 'NewSheinClub', 'ListProColor', 'RankingList', 'NewStarRating', 'ListAddToBag', 'NewPicSearch', 'AllListCropping', 'discountLabel', 'greysellingPoint', 'Listcategoryscreening', 'CateRec', 'listSpuPic', 'DiscountTag']
const searchWordsSceneStr = ['Search', 'SearchDefaultNew', 'SearchSuggestNew', 'SearchHotNew', 'SearchHotGD', 'ListTopLabel', 'Searchfilter', 'SearchFeedHotword', 'SearchTopNavigation', 'SpuPic', 'ListSearchSort', 'SearchPageLimitNum', 'SearchCategoryWord', 'SearchPageSort', 'CloudTagContent', 'SearchNoResultFeedHotword', 'SearchSuggestwordFeedback', 'PicSearch', 'PicSearchNew', 'listFilterAbt',  'showTspFilter', 'ListFilter', 'ListPriceFilter', 'ProductTypeLables', 'LabelShowFilter', 'ListTop', 'SearchPagefromDetail', 'SearchTrendNew', 'MoreSearchWord', 'SearchReco', 'RealtimeFeedbackJson', 'RecoPopup', 'goodsPicAb', 'searchSpuPic', 'SearchSimilar', 'Similaritems',]

// 全站搜索才有的abt
const allSearchSceneStr = [ 'SearchCard', 'SearchRecTips' ]

export class SA {
  constructor({ IS_RW }) {
    this.IS_RW = IS_RW
    // page_view需要上报的abtest (除搜索结果页外的泛列表，看看后面能不能统一)
    this.anaScenes = ['imageLabel', 'SameLabel', 'ShowPromotion', 'listFilterAbt', 'UserBehaviorLabel', 'listReview', 'ShowLabelClouds', 'OneTwoTitle', 'NewPosition', 'ListLoadmore', 'NewSheinClub', 'ListProColor', 'RankingList', 'showTspFilter', 'MostPopular', 'ListMostPopular', 'NewStarRating', 'ListAddToBag', 'AllListCropping', 'ListFilter', 'ListPriceFilter', 'ProductTypeLables', 'LabelShowFilter', 'ListTop', 'cateName', 'ListReco', 'RealtimeFeedbackJson', 'RecoPopup', 'discountLabel', 'greysellingPoint', 'Listcategoryscreening', 'CateRec', 'goodsPicAb', 'listSpuPic', 'ListGraphicNavigation']
  }

  resetPageParam({ catInfo, sum, searchKeywords, currentCat, fhContext, googleContext, goodsCrowId, listAbtResult, listFilterAbt, extraData, request_ext, hypernymRequestExt, searchTraceId, searchRequestStatus, searchRecRequestStatus }) {
    // eslint-disable-next-line no-unused-vars
    const abtest4List = this.getAbtTestData(listAbtResult, this.anaScenes, { listFilterAbt })
    const entityReqSearch = homeToEntityReqSearch(catInfo, listAbtResult)
    const { type, pageListType, styleType } = catInfo
    switch (true) {
      case type == 'entity' && !entityReqSearch:
        SaPageInfo.page_id = 3
        SaPageInfo.page_name = 'page_real_class'
        SaPageInfo.page_param = {
          category_id: catInfo.entity_id,
          source_category_id: currentCat.parent_id,
          page_list_type: pageListType,
          styleType,
          entrancetype: getQueryString({ key: 'entranceType' }) || '-'
          // abtest: abtest4List
        }
        break
      case type == 'selection':
        SaPageInfo.page_id = 46
        SaPageInfo.page_name = 'page_select_class'
        SaPageInfo.page_param = {
          category_id: catInfo.select_id,
          page_list_type: pageListType,
          styleType,
          entrancetype: getQueryString({ key: 'entranceType' }) || '-',
          product_select_id: getQueryString({ key: 'product_select_id' }) || ''
        }
        break
      case type == 'daily_new':
        SaPageInfo.page_id = 12
        SaPageInfo.page_name = 'page_goods_group'
        SaPageInfo.page_param = {
          group_content: 'daily_new',
          date: catInfo.daily || 'daily_new',
          result_count: sum || 0,
          page_list_type: pageListType,
          styleType,
        }
        break
      case type == 'search' || entityReqSearch:
        {
          const { word_type: word_from = '', result_type, cellsearchhot, pagefrom, entrancetype } = searchWordsGlobalAnalysis.get()
          const abtList = [...listAbtSceneStr, ...searchWordsSceneStr]// 区分全站搜索以及门店搜索结果页面

          if (catInfo.search_type !== 'store') { 
            abtList.push(...allSearchSceneStr) 
          }
          const abtest = this.getAbtTestData(listAbtResult, abtList,  { listFilterAbt })
          const searchWordArr = catInfo?.searchWord ? [catInfo.searchWord] : [] // 主搜词
          const filterWordsArr = catInfo?.filterWords?.split(',') || [] // 筛选词
          // 用户点击品类词7 历史词5 结果页child_id改为记录为"0"
          const resetChildIdSearchType = result_type === SEARCH_TYPE_ENUM.SUGGESTION_SEARCH_CATEGORY || result_type === SEARCH_TYPE_ENUM.RECENT_SEARCH
          const childId = resetChildIdSearchType ? { child_id: '0' } : {}

          const query_infor = Object.assign({}, hypernymRequestExt || {}, request_ext || {})
          const queryInforString = Object.entries(query_infor)
            .map(([key, value]) => `${key}_${value ?? ''}`)
            .join('|')

          SaPageInfo.page_param = {
            word_from,
            pagefrom: pagefrom || catInfo?.ici?.replace('`_fb`', searchKeywords.is_suggest_res ? '`fb1`' : '`fb0`'),
            result_type,
            search_content: searchWordArr.concat(filterWordsArr).join('>'),
            result_count: +searchKeywords.is_suggest_res === 1 ? 0 : sum || 0,
            rec_word: searchKeywords.suggest_words,
            rec_count: searchKeywords.suggest_total,
            crowd_id: this.IS_RW ? undefined : Object.keys(goodsCrowId)
              .map(key => `${key}_${goodsCrowId[key]}`)
              .join(),
            // 锦囊搜索；其中0代表命中实验下未使用、1代表使用；；空代表未命中实验
            cellsearchhot: cellsearchhot ? cellsearchhot : listAbtResult['PwaCellSearchHot']?.p?.PwaCellSearchHot?.includes('type=B') ? '0' : '',
            abtest,
            search_request_status: searchRequestStatus,
            search_rec_request_status: searchRecRequestStatus,
            query_infor: queryInforString,
            ...childId
          }
          if (getSearchDiff(catInfo).isStoreSearch) {
            SaPageInfo.page_id = 2987
            SaPageInfo.page_name = 'page_store_search'
            SaPageInfo.page_param.src_module = 'store_search'
            SaPageInfo.page_param.store_code = catInfo.store_code
            delete SaPageInfo.page_param?.abtest
          } else if (getSearchDiff(catInfo).isBrandSearch) {
            SaPageInfo.page_id = 2987
            SaPageInfo.page_name = 'page_chanel_search'
            SaPageInfo.page_param.src_module = 'chanel_search'
            SaPageInfo.page_param.tsp_code = catInfo.intent_channel_id
            delete SaPageInfo.page_param?.abtest
          } else {
            SaPageInfo.page_id = 16
            SaPageInfo.page_name = 'page_search'
            SaPageInfo.page_param.trace_id = searchTraceId || '-'
            SaPageInfo.page_param.result_content = window?.argResultContent || '-'
            SaPageInfo.page_param.entrancetype = entrancetype || '-'
            if (entityReqSearch) {
              SaPageInfo.page_param.category_id = catInfo.entity_id,
              SaPageInfo.page_param.result_type = 17
            }
          }   
        }
        break
      case type == 'selling':
        SaPageInfo.page_id = 21
        SaPageInfo.page_name = 'page_goods_group'
        SaPageInfo.page_param = {
          group_content: 'selling_point',
          // abtest: abtest4List,
          result_count: sum || 0,
          page_list_type: pageListType,
          styleType
        }
        break
    }

    function getTopNavigation (obj) {
      const { pageName, nav_to_query, nav_jump_type, pic_nav_id } = obj
      const isJump = nav_jump_type?.toString() === '1'
      let exposeType = 'topNavigation' // top_navigation: 原始;  top_navigation_query: 搜索图文点击转query; top_navigation_jump: 真实/选品跳转
      pageName === 'page_search' && nav_to_query && (exposeType = 'top_navigation_query')
      nav_jump_type?.toString() === '1' && !pic_nav_id && (exposeType = 'top_navigation_jump')

      if (exposeType !== 'topNavigation') {
        const picNavData = ['nav_query_pos', 'nav_query_type', 'nav_query_id', 'nav_query_adp', 'nav_query_rec_type', 'pic_tab_id', 'pic_tab_type', 'pic_tab_pos'].reduce((arr, key) => {
          arr.push(obj[key] || '')
          return arr
        }, [])
        const top_nav_list = [...picNavData, obj?.nav_query_name || '']
        if (pageName === 'page_search' && nav_to_query) {
          // word_source：导购词的数据来源，来源是搜索算法上报0，人工干预报1，获取不到就上报“-”
          top_nav_list.push(['1', '2'].includes(obj?.nav_query_type?.toString()) ? '0' : '1')
        }
        const topNavigation = top_nav_list.join('`')
        return {
          top_navigation: '',
          top_navigation_query: obj.nav_to_query ? topNavigation : '',
          top_navigation_jump: isJump ? topNavigation : ''
        }
      } 

      // 未选中图文，则不上报
      if (!obj.pic_nav_id || !obj.pic_nav_type || !obj.pic_nav_pos) return {}
      const picNavData = ['pic_nav_pos', 'pic_nav_type', 'pic_nav_id', 'pic_nav_adp', 'pic_rec_type'].reduce((arr, key) => {
        arr.push(obj[key] || '')
        return arr
      }, [])
      const picNavTabs = ['pic_tab_id', 'pic_tab_type', 'pic_tab_pos'].reduce((arr, key) => {
        arr.push(obj[key] || '')
        return arr
      }, [])
      const pic_nav_name = obj?.pic_nav_name || ''
      const list = [...picNavData, ...picNavTabs]
      pic_nav_name && list.push(pic_nav_name)
      if (pageName === 'page_search') {
        // word_source：导购词的数据来源，来源是搜索算法上报0，人工干预报1，获取不到就上报“-”
        list.push(['1', '2'].includes(obj?.nav_query_type?.toString()) ? '0' : '1')
      }

      return {
        top_navigation: list.join('`'),
        top_navigation_query: '',
        top_navigation_jump: ''
      }
    }

    const fallPageType = ['entity', 'selection', 'daily_new', 'search', 'picks', 'selling']
    if (fallPageType.includes(catInfo.type)) {
      SaPageInfo.page_param = {
        src_identifier: catInfo.url_from ? `uf=${catInfo.url_from}` : decodeURIComponent(catInfo.src_identifier || ''),
        src_module: catInfo.url_from ? 'ads' : catInfo.src_module || '',
        src_tab_page_id: catInfo.src_tab_page_id || '',
        ...SaPageInfo.page_param,
      }
    }

    const recResultCount = typeof extraData.recResultCount === 'number' ? extraData.recResultCount : '-'
    const rec_result_count = extraData.recResultCount === 'hidden' ? null : { rec_result_count: recResultCount }
    const recommend_count = catInfo.search_type !== 'store' ? { 'recommend_count': typeof extraData.recommendListPageNum === 'number' ? extraData.recommendListPageNum : '-' } : null
    SaPageInfo.page_param = {
      // page info
      category_id: '',
      source_category_id: '0',
      result_count: sum,

      // filt
      ...getTopNavigation(catInfo),
      sort: catInfo.sort || '0',
      sort_type: extraData.sortType || 'sort',
      child_id: catInfo.child_cat_id || '0',
      attribute: catInfo.attr_ids || '0',
      tsps: catInfo.tsp_ids || '0',
      price_range: `${catInfo.min_price || '-'}\`${catInfo.max_price || '-'}`,
      price_input: extraData.priceInput || '',
      tag_id: catInfo.tag_ids && `${catInfo.tag_ids}\`0` || catInfo.pic_cate_id && `${catInfo.pic_cate_id}\`1` || 0,

      // global traking
      aod_id: catInfo.aod_id || catInfo.ici?.match(/aod=([^_&]+)/)?.[1] || '0',
      pagefrom: catInfo.ici || catInfo.productsource,
      user_path: catInfo.userpath || '-',

      // list traking
      is_from_list_feeds: catInfo.is_from_list_feeds ? '1' : '2',
      sourceId: fhContext.rid,
      attributionToken: googleContext.attributionToken,

      result_Ioc: catInfo.result_Ioc || '',

      // 上位词商品总数
      ...rec_result_count,
      // 推荐商品总数
      ...recommend_count,

      ...SaPageInfo.page_param,
    }

    SaPageInfo.start_time = Date.now()
    
    sa('set', 'setPageData', SaPageInfo)
  }

  getAbtTestData(listAbtResult = {}, anaScenes, originAbtData = {}) {
    let recSwitch = listAbtResult?.recSwitch?.p?.recSwitch?.split('|')
    const abtInfo = anaScenes.reduce((acc, cur) => {
      if (cur === 'CateRec' && !recSwitch?.includes('157')) {
        cur = 'CateRecJson'
      }
      const result = listAbtResult[cur]
      const posKey = result?.sceneStr
      if (posKey) {
        acc[posKey] = result
      }
      return acc
    }, {})

    this.setUseAbtData(originAbtData, abtInfo)

    if (Object.keys(abtInfo)?.length) {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { sa } = abtservice.getUserAbtResultForAnalysis({ abtInfo }) || {}
      return sa || ''
    }
    return ''
  }

  setUseAbtData(abtData, abtInfo = {}) {
    if (abtData && typeof abtData === 'object') {
      Object.keys(abtData).forEach(k => {
        if (abtData[k]?.child) {
          abtservice.useState(k, abtData[k]) // 商品列表需要筛入abt内存中
          abtInfo[k] = abtData[k] // 当前pv需要
        }
      })
    }
  }
}
