
import { ref, watch } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getDailyList } from 'public/src/pages/components/FilterBar/utils/daily.js'


export function useDailyNavBar ({ filterBar, language }) {
  const list = ref([])

  watch(filterBar, () => {
    list.value = filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getDailyList(unrefs({ filterBar, language }))
  }, { immediate: true })
  return list
}

export  function useDailyTags ({ filterBar, language }) {
  const list = ref([])

  watch(filterBar, () => {
    list.value = !filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getDailyList(unrefs({ filterBar, language }))
  }, { immediate: true })
  return list
}
