import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

function addCccConfigMark (obj, cccConfig, ) {
  cccConfig.displayContent && (obj.cccConfig = { ...cccConfig })

  return obj
}

// 根据tagCloudSlotConfig 查找对应的tag的索引
function getTagIndexByTagCloudSlot (cloudTags, slotConfig) {
  const { type, tagId, tagType } = slotConfig
  if (tagId == 0 && (tagType == 11 || type == 'mall')) return cloudTags.findIndex(obj => obj.type == 1) // mall
  if (tagId == 0 && (tagType == 12 || type == 'quickship')) return cloudTags.findIndex(obj => obj.type == 2) // qs
  if(tagId == 14002) return cloudTags.findIndex(obj => obj.tagItemType == TAG_ITEM_TYPE.CATEGORY) // category
  if (tagId == 14003) return cloudTags.findIndex(obj => obj.tagItemType == TAG_ITEM_TYPE.PRICES) // price

  return cloudTags.findIndex(obj => tagId != 0 && obj.value == tagId)
}

// 标签插坑
export function tagListSortByTagCloudSlot (tagList = [], filterBar = {}) {
  const { tagCloudSlotConfig = [], isCccxFilterConfig } = filterBar?.filterConfig || {}
  if (!tagCloudSlotConfig.length) return tagList

  // 固定的tag【qs/mall/date】
  const fixTags = isCccxFilterConfig
    ? tagList.filter(tag => tag.tagItemType == TAG_ITEM_TYPE.DAILY) 
    : tagList.filter(tag => tag.type == 1 || tag.type == 2 || tag.tagItemType == TAG_ITEM_TYPE.DAILY)
  
  // 待插坑的元素[除 qs/mall/date 外的tag]
  let toSortTags = isCccxFilterConfig
    ? tagList.filter(tag => tag.tagItemType != TAG_ITEM_TYPE.DAILY) 
    : tagList.filter(tag => tag.type != 1 && tag.type != 2 && tag.tagItemType != TAG_ITEM_TYPE.DAILY)
  
  // 已排序的tag
  const sortList = []
  const map = {} // {1: [tag1, tag2], 3: [tag3], 5: [tag4]}
  tagCloudSlotConfig.forEach(config => {
    const index = getTagIndexByTagCloudSlot(toSortTags, config)
    if (index < 0) return 

    const tag = toSortTags.splice(index, 1)[0]
    if (!tag) return

    addCccConfigMark(tag, config)
    !map[config.pitValue] && (map[config.pitValue] = [])
    map[config.pitValue].push(tag)
  })

  Object.keys(map)
    .sort((a, b) => a - b)
    .forEach(i => { 
      const index = Math.max(0, (i - 1))
      if (sortList[index]) {
        sortList.push(...map[i])
        return
      }
      
      let length = sortList.length
      while (length < index) {
        const slotTag = toSortTags.shift()
        if (!slotTag) break
        sortList.push(slotTag)
        length++
      }
      sortList.push(...map[i])
    })
  
  return [...fixTags, ...sortList, ...toSortTags]
}

// cccxFilter 排序
export function tagSortByCccxFilter (tagList = [], filterBar = {}) {
  const { cccxFilterConfig = {} } = filterBar?.filterConfig || {}
  if (!tagList.length || !cccxFilterConfig.length) return tagList

  let newList = []

  // cloud tags / daily
  newList = newList.concat(tagList.filter(tag => [
    TAG_ITEM_TYPE.CLOUD_TAG,
    TAG_ITEM_TYPE.DAILY
  ].includes(tag.tagItemType)))

  // category
  const cateCccxConfig = cccxFilterConfig.find(node => node.nodeType == 6) // cccxConfig中分类配置
  const cateIndex = tagList.findIndex(item => item?.tagItemType === TAG_ITEM_TYPE.CATEGORY)
  if (cateIndex > -1 && !cateCccxConfig) { 
    newList.push(tagList[cateIndex])
  }

  // cccxConfig 处理 tsp/attr
  cccxFilterConfig.forEach(node => {
    let index = node.nodeType == 6
      ? tagList.findIndex(item => item?.tagItemType === TAG_ITEM_TYPE.CATEGORY)
      : tagList.findIndex(item => item.id == node.nodeId && item.nodeType == node.nodeType)

    if (index < 0) return

    newList.push(tagList[index])
  })

  // prices
  const priceIndex = tagList.findIndex(item => item?.tagItemType === TAG_ITEM_TYPE.PRICES)
  if (priceIndex > -1) {
    newList.push(tagList[priceIndex])
  }

  return newList
}

