
import {  ref, watch, computed } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'

// utils
import {
  setMenuConfigByCloudTags,
  setMenuConfigByCategory,
  setMenuConfigByTsp,
  setMenuConfigByPrices,
  setMenuConfigByAttr,
  sortMenuConfig,
  setMenuActive,
} from 'public/src/pages/components/FilterBar/NavBar/utils/menuConfig.js'

export function useMenuConfig ({ locals, catInfo, filterBar, queryParams, language, listAbtResult }) {
  const menuConfig = ref({ menuBarConfig: [], menuItemsConfig: [] })

  watch(filterBar, () => {
    const config = { menuBarConfig: [], menuItemsConfig: [] }
    setMenuConfigByCategory(unrefs({ config, catInfo, listAbtResult, filterBar, language, queryParams }))
    setMenuConfigByTsp(unrefs({ config, filterBar, queryParams }))
    setMenuConfigByAttr(unrefs({ config, filterBar, queryParams }))
    setMenuConfigByPrices(unrefs({ config, locals, filterBar, queryParams, language, listAbtResult }))
    setMenuConfigByCloudTags(unrefs({ config, filterBar, queryParams, language, listAbtResult }))
    
    sortMenuConfig(unrefs({ config, filterBar }))
    setMenuActive(unrefs({ config, filterBar }))

    menuConfig.value = config

  }, { immediate: true })

  return menuConfig
}

export function useMenuFilterNum (menuConfig) {
  return computed(() => {
    return menuConfig.value.menuBarConfig.reduce((acc, cur) => {
      acc += cur.filterNum

      return acc
    }, 0)
  })
}
