<template>
  <Slidetab
    ref="slideTabComp"
    :value="activeIndex"
    :reverse="reverse"
    :invert-select="true"
    :space-between="spaceBetween"
    :slide-ready="secondScreenItemsVisible || !firstRendering"
    dynamic-slide
    @done="onDone"
  >
    <SlidetabItem
      v-for="(item, index) in firstScreenItems"
      :key="item.value"
      :slide-id="index"
      @change="onChange"
    >
      <PicNavItem
        :is-small-status="isSmallStatus"
        :is-active="activeValue === item.value"
        :is-config-active="isConfigActive"
        :is-show-icon="isShowIcon"
        :shape="shape"
        :label="item.label"
        :src="item.src"
        :lazy-img="item.lazyImg"
        :is-lazy-load="item.isLazyLoad"
        :analysis-data="item.analysisData"
        :cssRight="constantData.GB_cssRight"
      />
    </SlidetabItem>
    
    <template v-if="secondScreenItemsVisible || !firstRendering">
      <SlidetabItem
        v-for="(item, index) in secondScreenItems"
        :key="item.value"
        :slide-id="index + 6"
        @change="onChange"
      >
        <PicNavItem
          :is-small-status="isSmallStatus"
          :is-active="activeValue === item.value"
          :is-config-active="isConfigActive"
          :is-show-icon="isShowIcon"
          :shape="shape"
          :label="item.label"
          :src="item.src"
          :lazy-img="item.lazyImg"
          :is-lazy-load="item.isLazyLoad"
          :analysis-data="item.analysisData"
          :cssRight="constantData?.GB_cssRight"
        />
      </SlidetabItem>
    </template>
    <template slot="right">
      <slot name="right"></slot>
    </template>
  </Slidetab>
</template>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
// components
import Slidetab from 'public/src/pages/common/slidetab/SlideTab.vue'
import SlidetabItem from 'public/src/pages/common/slidetab/SlidetabItem.vue'
import PicNavItem from './PicNavItem.vue'

const emits = defineEmits(['change'])
const props = defineProps({
  list: { type: Array, default: () => [] },
  reverse: { type: Boolean, default: false },
  isConfigActive: { type: Boolean, default: true }, // 是否配置激活，如可跳转图文，不需要激活，点击直接跳转页面
  isSmallStatus: { type: Boolean, default: false },
  isShowIcon: { type: Boolean, default: true },
  spaceBetween: { type: Number, default: 8 },
  shape: { type: String, default: PIC_TOP_NAV_SHAPE.RECT },
  firstRendering: { type: Boolean, default: true },
  activeValue: { type: [String, Number], default: '' } // 当前选中的值
})
const constantData = inject('constantData', {})

const activeIndex = computed(() => {
  if (!props.isConfigActive) return -1

  return props.list.findIndex(item => item.value === props.activeValue)
})
const onChange = (index, isSelect) => {
  emits('change', index, isSelect)
}

const onDone = (...args) => emits('done', ...args)

const slideTabComp = ref()
const resetMoveX = () => slideTabComp.value?.resetMoveX()
defineExpose({ resetMoveX })

const secondScreenItemsVisible = ref(false)
const setScendScreenItemsVisible = () => {
  const timer = setTimeout(() => {
    secondScreenItemsVisible.value = true
    clearTimeout(timer)
  }, 1500)
}

const firstScreenItems = computed(() => props.list.slice(0, 6))
const secondScreenItems = computed(() => props.list.slice(6))

onMounted(() => {
  setScendScreenItemsVisible()
})
</script>
