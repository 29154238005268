import { ref } from 'vue'

export function useStickyCommonConfig (fixedSmallStatus) {
  const isStickyStatus = ref(false) // 吸顶中
  const isSmallStatus = ref(fixedSmallStatus?.value === 'small') // 小图模式
  const isScrollDown = ref(false) // 是否向下滚动
  const stickyPosition = ref('sticky') // sticky el的布局 可能为initial、sticky、fixed
  return {
    isStickyStatus,
    isSmallStatus,
    isScrollDown,
    stickyPosition
  }
}
