<template>
  <SlideExpand
    v-if="showStatus"
    :list="list"
    :two-line-style="twoLineStyle"
    :constant-data="constantData"
    :is-small-status="isSmallStatus"
    :is-config-active="isConfigActive"
    :is-show-icon="isShowIcon"
    :active-value="activeValue"
    :shape="shape"
    :first-rendering="firstRendering"
    :move-x="moveX"
    :set-move-x="setMoveX"
    :show-next-icon="showNextIcon"
    :tab-index="tabIndex"
    :active-index="activeIndex"
    :set-active-index="setActiveIndex"
    :is-card-tab="isCardTab"
    @change="onChange"
    @slide-next="onDone"
  >
    <template
      v-if="isMounted && list.length > 6 && showNextIcon"
      #right
    >
      <SlideNext :label="language.SHEIN_KEY_PWA_21218" />
    </template>
  </SlideExpand>
  <PicNavList
    v-else
    ref="picNavListComp"
    :list="list"
    :space-between="spaceBetween"
    :reverse="constantData.GB_cssRight"
    :is-small-status="isSmallStatus"
    :is-config-active="isConfigActive"
    :is-show-icon="isShowIcon"
    :active-value="activeValue"
    :shape="shape"
    :first-rendering="firstRendering"
    @change="onChange" 
    @done="onDone"
  >
    <template 
      v-if="showNextIcon"  
      #right
    >
      <SlideNext :label="language.SHEIN_KEY_PWA_21218" />
    </template>
  </PicNavList>
</template>

<script>

import { defineComponent, ref, watch, onMounted, inject, computed } from 'vue'

import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { useSlideActiveIndex } from 'public/src/pages/components/FilterBar/PicTopNav/hooks'
import { calcItemPadding, showTwoLineFromEntry, getTwoLineStyle } from '../tools'

import PicNavList from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavList.vue'
import SlideNext from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavTabs/SlideNext.vue'
import SlideExpand from './Container.vue'

export default defineComponent({
  name: 'WithTabsContainer',
  components: {
    PicNavList,
    SlideExpand,
    SlideNext,
  },
  props: {
    language: { type: Object, default: () => ({}) },
    showNextIcon: { type: Boolean, default: false },
    tabIndex: { type: Number, default: 0 },
    onChange: { type: Function, default: function () {} },
    onDone: { type: Function, default: function () {} },
    constantData: { type: Object, default: () => ({}) },
    list: { type: Array, default: () => [] },
    isConfigActive: { type: Boolean, default: true }, // 是否配置激活，如可跳转图文，不需要激活，点击直接跳转页面
    isSmallStatus: { type: Boolean, default: false },
    isShowIcon: { type: Boolean, default: true },
    shape: { type: String, default: PIC_TOP_NAV_SHAPE.RECT },
    firstRendering: { type: Boolean, default: true },
    activeValue: { type: [String, Number], default: '' }, // 当前选中的值
    // 带tab的图文导航根据第一个tab是否左滑展开两行来判断
    firstTabStatus: { type: String, default: '' },
    setFirstTabStatus: { type: Function, default: function () {} },
    isCardTab: { type: Boolean, default: false }, // 是否是卡片tab布局
  },
  setup (props, { emit }) {
    const catInfo = inject('catInfo', {})
    const listAbtResult = inject('listAbtResult', {})

    const { activeIndex, setActiveIndex } = useSlideActiveIndex(props)

    const itemPadding = ref(12)
    const spaceBetween = computed(() => props.isSmallStatus ? 6 : itemPadding.value)
    const showFromEntry = computed(() => showTwoLineFromEntry({
      catInfo,
      listAbtResult,
    }))

    const twoLineStyle = computed(() => getTwoLineStyle({ listAbtResult, catInfo }))
    const showSlideExpand = computed(() => {
      if (props.isSmallStatus || props.shape === PIC_TOP_NAV_SHAPE.RECT) {
        return false
      }
      const total = props.list.length
      return twoLineStyle.value ?
        (twoLineStyle.value === 'FirstScreen' ? total >= 8 : total >= 13) :
        false
    })

    const showStatus = computed(() => {
      if (props.isSmallStatus) {
        return false
      }
      const flag = showSlideExpand.value && showFromEntry.value
      if (props.tabIndex === 0) {        
        return flag
      }
      return props.firstTabStatus === 'SLIDE_EXPAND'
    })

    const { moveX, setMoveX, picNavListComp } = useSlideNextMove(props, showStatus)

    const isMounted = ref(false)

    onMounted(() => {
      isMounted.value = true
      if (showStatus.value) {
        itemPadding.value = calcItemPadding({
          isCardTab: props.isCardTab,
        })
      }
    })

    watch(activeIndex, () => {
      appEventCenter.$emit('changePicTopNavHight')
    })

    watch(showStatus, () => {
      emit('changeShowStatus', showStatus.value)
    }, {
      immediate: true
    })

    return {
      activeIndex,
      setActiveIndex,
      isMounted,
      showStatus,
      twoLineStyle,
      picNavListComp,
      spaceBetween,
      moveX,
      setMoveX,
    }
  },
})

const useSlideNextMove = (props, showStatus) => {
  const moveX = ref(0)
  const setMoveX = (num) => {
    moveX.value = num
  }

  const picNavListComp = ref(null)
  watch(() => props.tabIndex, () => {
    setMoveX(0)
    picNavListComp.value?.resetMoveX()
    const newStatus = showStatus.value ? 'SLIDE_EXPAND' : ''
    if (newStatus !== props.firstTabStatus) {
      props.setFirstTabStatus(newStatus)
    }
  }, { immediate: true })

  return {
    picNavListComp,
    moveX,
    setMoveX,
  }
}
</script>
