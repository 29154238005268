<template>
  <div
    :class="{
      'nav-bar__sort': true,
      'nav-bar__sort-selected': isSelected
    }"
  >
    <DropdownMenu 
      no-title
      border-top
      :label="label"
      :menu-list="menuList"
      :loading="loading"
      :disabled="isDisabled"
      :open-delay="autoStickyTime"
      :close-bottom-left="closeBottomLeft"
      :default-select-index="defaultSelectIndex"
      @click="onClick"
      @beforeOpen="toStickyByClick"
      @clickMenuItem="onClickMenuItem"
      @visibleChange="onVisibleChange"
    />
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import DropdownMenu from 'public/src/pages/components/FilterBar/components/dropdown/DropdownMenu.vue'
import { analysisClickSort, analysisClickSortEntrance } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

const emits = defineEmits(['change'])
const sortMap = inject('sortMap', [])
const loading = inject('loading') 
const filterBar = inject('filterBar', {}) 
const topTwoList = inject('topTwoList', [])
const queryParams = inject('queryParams', {}) 
const closeBottomLeft = inject('closeBottomLeft', true) 
const autoStickyTime = inject('autoStickyTime', 0) 

const menuList = computed(() => {
  return sortMap.value.map(item => {
    return {
      label: item.label,
      value: item.value,
    }
  })
})

const defaultSelectIndex = computed(() => {
  const sort = queryParams.value?.sort
  if (!sort) return 0
  const index = menuList.value.findIndex(item => item.value == sort)

  return index > -1 ? index : ''
})

// 是否禁止展开panel[]
const isDisabled = computed(() => {
  if (!filterBar?.value?.filterConfig?.isExportFilterSort) return false // 非sort逻辑交互优化
  if (!queryParams.value?.sort) return false // 默认recommned，可以展开
  return Boolean(!menuList.value.find(item => item.value == queryParams.value?.sort)) 
})

const label = computed(() => {
  const index = defaultSelectIndex.value
  return index ? menuList.value[index]?.label : menuList.value[0]?.label
})

const isSelected = computed(() => {
  if (!queryParams.value?.sort) return true

  return Boolean(menuList.value.find(item => item.value == queryParams.value?.sort))
})

const toStickyByClick = () => emits('toStickyByClick')
const onClick = () => {
  let sort = queryParams.value.sort || 0
  
  if (isDisabled.value) {
    const item = menuList.value[0] || {}
    sort = item.value
    toStickyByClick()
    analysisClickSort({ sort, sortType: item.label, clickType: 'sort' })
    emits('change', { params: { sort } })
  }
  analysisClickSortEntrance(unrefs({ sort, sortMap, topTwoList, queryParams }))

}


const onClickMenuItem = ({ payload }) => {
  emitCloseDropdown()
  const sort = payload.data.value
  analysisClickSort({ sort, sortType: payload.data.label, clickType: 'sort' })
  emits('change', {
    params: {
      sort: payload.data.value,
      source: 'sort', 
      source_status: 1
    }
  })
}
const onVisibleChange = flag => emits('visibleChange', flag)
</script>

<style scoped lang="less">
.nav-bar__sort {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.nav-bar__sort-selected {
  /deep/ .dropdown-label__content {
    font-weight: 700;
  }
}
</style>
