
import { ref, watch } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getAttrList } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

export function useAttrNavBar ({ filterBar, picTopNav, queryParams }) { 
  const list = ref([])

  watch(filterBar, () => {
    list.value = filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getAttrList(unrefs({ filterBar, picTopNav, queryParams, filterBarType: FILTER_BAR_MAP.NAV_BAR }))
  }, { immediate: true })

  return list
}

export function useAttrTags ({ filterBar, picTopNav, queryParams }) {
  const list = ref([])
  
  watch(filterBar, () => {
    list.value = !filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getAttrList(unrefs({ filterBar, picTopNav, queryParams, filterBarType: FILTER_BAR_MAP.CLOUD_TAGS }))
  }, { immediate: true })

  return list
}

