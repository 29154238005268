<template>
  <div
    :class="{
      'ptn-list__container': true,
      'ptn-list__container-small': isSmallStatus,
    }"
  >
    <SwitchContainer
      :list="formatList"
      :is-config-active="!isToQuery"
      :active-value="activeValue"
      :shape="isToQuery ? PIC_TOP_NAV_SHAPE.CIRCLE : PIC_TOP_NAV_SHAPE.RECT"
      :on-change="onChange"
    />
    <div
      v-show="formatList.length && !isSmallStatus"
      class="pictn-bottom"
    ></div>
  </div>
</template>

<script setup>
import { inject, computed, getCurrentInstance } from 'vue'
import SwitchContainer from './slide-expand/SwitchContainer.vue'

import { useSearchFilterWords } from 'public/src/pages/components/FilterBar/hooks/useVuex.js'
import { useRoute } from 'public/src/pages/components/FilterBar/hooks/useRouter.js'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'

import { handlePicTopNavParams } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import {
  getIsToQuery,
  onChangeByToQuery
} from 'public/src/pages/components/FilterBar/PicTopNav/utils/toQuery.js'
import { getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import { RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'

const emits = defineEmits(['change'])
const list = inject('list', {})
const locals = inject('locals', {})
const catInfo = inject('catInfo', {})
const queryParams = inject('queryParams', {})
const listAbtResult = inject('listAbtResult', {})
const isSmallStatus = inject('isSmallStatus', false)

const activeValue = computed(() => queryParams.value?.pic_nav_id || '')

const formatList = computed(() => {
  const tabItem = list.value?.[0] || {}
  const { navs = [] } = tabItem
  return navs.map((navItem, navIndex) => ({
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    isLazyLoad: navIndex > 5,
    lazyImg: locals.value.LAZY_IMG,
    analysisData: getPicTopNavAnalysisData({
      tabItem,
      navItem,
      navIndex,
      catInfo
    })
  }))
})
const route = useRoute()
const currentInstance = getCurrentInstance()
const instance = computed(() =>
  Object.assign(currentInstance.proxy, { catInfo: catInfo.value })
)
const searchFilterWords = useSearchFilterWords()
const isToQuery = computed(() =>
  getIsToQuery(unrefs({ catInfo, listAbtResult }))
)
const onChange = (navIndex, isSelect) => {
  analysis.clickPicTopNav(
    handlePicTopNavParams(
      unrefs({
        list,
        tabIndex: 0,
        navIndex,
        noClearParams: true,
        extraParams: {
          nav_to_query: isToQuery.value ? 1 : 0,
          pageName: catInfo.value.pageName,
        }
      })
    )
  )

  if (isToQuery.value) {
    onChangeByToQuery(
      unrefs({
        list,
        route,
        tabIndex: 0,
        navIndex,
        searchFilterWords,
        instance,
        isSelect,
        extraQuery: {
        },
        listAbtResult
      })
    )
    return
  }
  const params = handlePicTopNavParams(
    unrefs({ list, tabIndex: 0, navIndex, isSelect, businessReport: true })
  )

  emits('change', {
    params: {
      ...RESTET_QUERY_PARAMS,
      ...params
    }
  })
}
</script>

<style lang="less" scoped>
.ptn-list {
  &__container {
    padding: 6px 12px; // @flag-pic-padding 改之前搜一下这个标记
    padding-bottom: 0;
    background: #fff;
  }
  &__container-small {
    padding: 6px;
  }
}

.pictn-bottom {
  width: 10rem; // document.body最大宽度是10rem
  height: 4px;
  background: #f5f5f5;
  margin-top: 6px;
  margin-left: -12px; // @flag-pic-padding 改之前搜一下这个标记
}
</style>
