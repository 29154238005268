
import { ref, watch } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getTspList } from '../utils/tsp'

export function useTspNavBar ({ filterBar, queryParams }) {
  const list = ref([])
  watch(filterBar, () => {
    list.value = filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getTspList(unrefs({ filterBar, queryParams }))
  }, { immediate: true })

  return list
}

export function useTspTags ({ filterBar, queryParams }) { 
  const list = ref([])
  watch(filterBar, () => {
    list.value = !filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getTspList(unrefs({ filterBar, queryParams }))
  }, { immediate: true })

  return list
}
