<template>
  <div class="nav-bar attr-export__container">
    <div class="attr-export__item">
      <NavBarSort 
        @change="onChange"   
        @visibleChange="onVisibleChange"
        @toStickyByClick="onToStickyByClick"
      />
    </div>
    <div
      v-for="item in topTwoList"
      :key="item.value"
      class="attr-export__item"
    >
      <TagItem
        :item="item"
        :tag-all-list="tagAllList"
        :daily-tags="dailyTags"
        :cate-tags="cateTags"
        :tsp-tags="tspTags"
        :attr-tags="attrTags"
        @beforeOpen="onTagItemClick(item)"
        @change="onTagItemChange"
        @saveCurrAttr="onSaveCurrAttr"
        @visibleChange="onVisibleChange"
      />
    </div>
    <div class="attr-export__item">
      <NavBarFilter 
        ref="navBarFilter"
        :filter-num="filterNum"
        :menu-config="menuConfig"
        @change="onChange"
        @saveCurrAttr="onSaveCurrAttr"
        @visibleChange="onVisibleChange"
        @toStickyByClick="onToStickyByClick"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import NavBarSort from '../NavBarItem/NavBarSort.vue'
import NavBarFilter from '../NavBarItem/NavBarFilter.vue'
import TagItem from 'public/src/pages/components/FilterBar/CloudTags/components/TagItem/index.vue'

// hooks

// hooks
import { useDailyNavBar } from '@/public/src/pages/components/FilterBar/hooks/useDaily.js'
import { useCateNavBar } from '@/public/src/pages/components/FilterBar/hooks/useCategory.js'
import { useTspNavBar } from 'public/src/pages/components/FilterBar/hooks/useTsp.js'
import { useAttrNavBar } from '@/public/src/pages/components/FilterBar/hooks/useAttr.js'
import { useMenuConfig, useMenuFilterNum } from 'public/src/pages/components/FilterBar/NavBar/hooks/useMenuConfig.js'

import { analysisClickTagItem } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils'

const emits = defineEmits(['change'])
const locals = inject('locals', {})
const catInfo = inject('catInfo', {})
const filterBar = inject('filterBar', {})
const language = inject('language', {})
const queryParams = inject('queryParams', {})
const picTopNav = inject('picTopNav', [])
const listAbtResult = inject('listAbtResult', {})

const menuConfig = useMenuConfig({ locals, catInfo, filterBar, queryParams, language, listAbtResult })
const filterNum = useMenuFilterNum(menuConfig)

const dailyTags = useDailyNavBar({ filterBar, language })
const cateTags = useCateNavBar({ catInfo, filterBar, language, listAbtResult, queryParams })
const tspTags = useTspNavBar({ filterBar, queryParams })
const attrTags = useAttrNavBar({ filterBar, picTopNav, queryParams })

const tagAllList = computed(() => {
  return [
    ...dailyTags.value,
    ...cateTags.value,
    ...tspTags.value,
    ...attrTags.value
  ]
})

const topTwoList = computed(() => {
  const list = tagAllList.value
  return list.length > 2 ? list.slice(0, 2) : list
})

const onTagItemChange = (params) => {
  emits('change', params)
}

const onTagItemClick = (item) => {
  analysisClickTagItem(unrefs({ item, tagAllList, queryParams }))
}

const navBarFilter = ref()
const openSideFilter = () => { 
  navBarFilter.value?.openSideFilter()
}

const onChange = params => emits('change', params)
const onToStickyByClick = () => emits('toStickyByClick')
const onSaveCurrAttr = (id, type) => emits('saveCurrAttr', id, type)
const onVisibleChange = flag => emits('visibleChange', flag)

defineExpose({ openSideFilter })
</script>

<style scoped lang="less">
.attr-export {
  &__container{
    display: flex;
    width: 100%;
    height: 1.17rem;
    align-content: center;
    background: #fff;
    justify-content: space-between;
  }

  &__item {
    &:last-child {
      padding-right: 12/ 37.5rem;
    }

  }
}

.nav-bar {
  /deep/ .tag-dropdown__container .dropdown-common__label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    font-size: 14px;
  }

  /deep/ .tag-dropdown__container .dropdown-common__label {
    margin-bottom: 0;
    background: #fff;
  }

  /deep/ .dropdown-common__active .dropdown-common__label {
    background: #fff;
  }
}
</style>
