import { computed } from 'vue'
import { NAV_BAR_ONE_TWO_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

// sortExport most popular/price 
export function useTopTwoList ({ filterBar, language, sortAllList, queryParams }) {
  return computed(() => {
    const list = []

    // most popular
    if (filterBar.value?.filterConfig?.isShowMostPopularBar) {
      const data = sortAllList.value.find(item => /popular/i.test(item.label_en))

      list.push({
        value: data.value,
        oneTwoType: NAV_BAR_ONE_TWO_TYPE.BUTTON,
        active: queryParams.value?.sort == data.value,
        label: language.value?.SHEIN_KEY_PWA_15556
      })
    }

    // prices
    if (filterBar.value?.filterConfig?.isShowPriceBar) {
      const ascItem = sortAllList.value.find(item => /Price Low To High/i.test(item.label_en))
      const descItem = sortAllList.value.find(item => /Price High To Low/i.test(item.label_en))

      list.push({
        oneTwoType: NAV_BAR_ONE_TWO_TYPE.PRICE,
        value: queryParams.value?.sort,
        label: language.value?.SHEIN_KEY_PWA_14953,
        ascValue: ascItem?.value,
        descValue: descItem?.value
      })
    }

    return list
  })
}

// 兜底平铺排序
export function useSortQuickList ({ sortMap2, queryParams }) {
  return computed(() => {
    return sortMap2.value.map(item => ({
      value: item.value,
      label: item.label,
      active: queryParams.value?.sort == item.value,
      icon: item.label_en == 'Price Low To High' ? 'icon-price_px_' : item.label_en == 'Price High To Low' ? 'icon-price_px_-' : ''
    }))
  })
}
