/**
 * 处理点击图文跳转真实分类页
 */

import { getCurrentInstance } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { handlePicTopNavParams } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'

export default function useToRealPage ({ list, extraParams }) {
  const vm = getCurrentInstance()
  const route = vm.proxy.$route
  const router = vm.proxy.$router

  if (!vm || !route || !router) return () => {}
  const onChange = ({ tabIndex = 0, navIndex }) => {
    const navItem = list.value?.[tabIndex]?.navs?.[navIndex]
    if (!navItem) return
    const { nav_id, nav_name, nav_type, rec_type, goodsId = '' } = navItem
    const { src_identifier, src_module, src_tab_page_id, } = route.query
    const query = {
      adp: goodsId,
      src_identifier,
      src_module,
      src_tab_page_id,
      nav_query_adp: goodsId,
      nav_query_id: nav_id,
      nav_query_type: nav_type,
      nav_query_pos: navIndex + 1,
      nav_query_name: nav_name,
      nav_query_rec_type: rec_type,
      nav_jump_type: 1,
      source: 'imgNavQuery',
      source_status: 1
    }
    const params = handlePicTopNavParams(unrefs({ list, tabIndex, navIndex, noClearParams: true, extraParams: {
      pic_tab_jump_type: 1,
      ...extraParams,
    }}))
    markPoint('toNextPageClick', 'public')
    const id = String(nav_id).replace(/^3000/, '')

    analysis.clickPicTopNav(params)
    router.push({
      query,
      path: `${encodeURIComponent(nav_name)}-c-${id}.html`,
    })
  }

  return onChange
}
