<template>
  <div
    :class="{
      'nav-bar__filter': true,
    }"
    :aria-label="language.SHEIN_KEY_PWA_15044"
    role="tab"
    @click="onClick"
    @focus="$emit('navFocus', $event)"
  >
    <span
      :class="{
        'nav-bar__filter-label': true,
        'nav-bar__filter-label-highlight': filterNum,
      }"
    >
      {{ language.SHEIN_KEY_PWA_15044 }}
    </span>

    <!-- icon -->
    <span
      :style="{
        color: filterNum ? '#222' : '#666'
      }"
    >
      <Icon
        size="16px"
        name="sui_icon_filter_16px"
      />
    </span>

    <!-- filterNum -->
    <span
      v-if="filterNum"
      class="nav-bar__filter-filter-num"
    >
      {{ filterNum }}
    </span>

    <NavBarFilterDrawer 
      v-if="isLoadSlide"
      ref="refNavBarFilterDrawer"
      :visible.sync="visible"
      :menu-bar-config="menuConfig.menuBarConfig"
      :menu-items-config="menuConfig.menuItemsConfig"
      @sidePanelChange="onSidePanelChange"
      @resetAll="onResetAll"
      @closeSidePanel="close"
      @close="close"
      @clickDone="close"
    />
  </div>
</template>

<script setup>
import { unref, watch, toRefs, inject, defineAsyncComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

// hooks
import { useVisible, useIsLoadSlide } from 'public/src/pages/components/FilterBar/components/dropdown/hooks/index.js'

import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { RESTET_QUERY_PARAMS } from 'public/src/pages/components/FilterBar/utils/const.js'
import { handlePricesParams } from 'public/src/pages/components/FilterBar/utils/prices.js'
import { handleCategoryParams } from 'public/src/pages/components/FilterBar/utils/category.js'
import { handleAttrParams } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { analysisClickFilterEntrance } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs, getSourceInsideFilterOrCloudTagPanel } from 'public/src/pages/components/FilterBar/utils/index.js'
import { isFilterByKids } from 'public/src/pages/components/FilterBar/utils/kids.js'
const NavBarFilterDrawer = defineAsyncComponent(() => import('./NavBarFilterDrawer.vue'))

const props  = defineProps({
  menuConfig: { type: Object, default: () => ({}) },
  filterNum: { type: Number, default: 0 },
})

const { menuConfig, filterNum } = toRefs(props)

// inject
const sortMap = inject('sortMap', [])
const topTwoList = inject('topTwoList', [])
const language = inject('language', {})
const filterBar = inject('filterBar', {})
const queryParams = inject('queryParams', {})
const fhContext = inject('fhContext', {})
const googleContext = inject('googleContext', {})
const autoStickyTime = inject('autoStickyTime', 0) 
const isConfigAutoSticky = inject('isConfigAutoSticky', false) 

const { visible, toggle, close } = useVisible()
const isLoadSlide = useIsLoadSlide(visible)
const emits = defineEmits(['change'])
watch(visible, flag => emits('visibleChange', flag))
const toStickyByClick = () => emits('toStickyByClick')
const onClick = () => {
  if (!isConfigAutoSticky.value) {
    analysisClickFilterEntrance(unrefs({ sortMap, topTwoList, queryParams }))
    toggle()
    return
  }

  toStickyByClick()
  const timer = setTimeout(() => {
    clearTimeout(timer)
    analysisClickFilterEntrance(unrefs({ sortMap, topTwoList, queryParams }))
    toggle()
  }, autoStickyTime.value)
}

const openSideFilter = () => {
  onClick()
}

// events
const onResetAll = () => {
  analysis.sideClickReset()
  const { tag_ids }  = queryParams.value

  emits('change', { 
    rightFilterFalg: true,
    params: { 
      ...RESTET_QUERY_PARAMS,
      tag_ids: isFilterByKids(tag_ids) ? tag_ids : ''
    }
  })
}

const onShopTypeChange = ({ data = {} }) => {
  const { value, mall_tag } = data
  const index = (filterBar.value?.filterData?.cloudTags || []).findIndex(tag => tag.value == value)
  // 埋点
  index > -1 && analysis.clickCloudTag({
    tagId: value,
    tagIndex: index + 1,
    facet: fhContext?.value?.tag_id,
    gfilter: googleContext?.value?.gfilter,
  })

  emits('change', {
    data,
    type: 'shopType',
    rightFilterFalg: true,
    params: { tag_ids: value, mall_tag, pic_cate_id: '', source: 'insideFilter', source_status: value ? 1 : 0 }
  })
}

const onCategoryChange = ({ data, cancel, paths }) => {
  emits('change', {
    data,
    cancel,
    type: 'cate',
    rightFilterFalg: true,
    params: handleCategoryParams({
      data,
      paths,
      doAnalysis: true,
      facet: fhContext?.value?.categories,
      gfilter: googleContext?.value?.gfilter,
      isCancel: cancel,
      sourceFrom: 'sideFilter',
      filterCates: filterBar.value?.filterData?.filterCates
    })
  })
}

const onPriceChange = ({ minPrice, maxPrice, startMinPrice, startMaxPrice, priceInput }) => {
  emits('change', {
    type: 'price',
    rightFilterFalg: true,
    params: handlePricesParams({
      minPrice,
      maxPrice,
      priceInput,
      clickType: 'filter',
      startMinPrice,
      startMaxPrice,
      fhContext: unref(fhContext),
      googleContext: unref(googleContext),
      sourceFrom: 'sideFilter',
    })
  })
}

const onAttrChange = ({ data, operation, curSelectedItem, curSelectedNum, }) => {
  curSelectedNum && emits('saveCurrAttr', curSelectedItem?.id || curSelectedItem?.value, 'attr')

  // 处理自定义筛选中配置tsp
  if (curSelectedItem.nodeType == 7 && filterBar.value?.filterConfig?.isCccxFilterConfig) {
    const tspId = `${curSelectedItem.id}_${curSelectedItem.mix_value}`
    const list = menuConfig.value.menuItemsConfig
      .filter(item => item.type == 'tsp')
      .reduce((acc, cur) => {
        return acc.concat(cur.data?.data?.filter(obj => obj.active && tspId != obj.id) || [])
      }, [])
    
    list.forEach(obj => {
      const splits = obj.id.split('_')
      data.push({
        nodeType: 7,
        id: splits[0],
        mix_value: splits[1],
      })
    })
  }

  emits('change', {
    type: 'attr',
    rightFilterFalg: true,
    params: handleAttrParams({
      data,
      operation,
      clickType: 'Filter',
      curSelectedItem,
      curSelectedNum,
      queryParams: queryParams.value,
      isCccxFilterConfig: filterBar.value?.filterConfig?.isCccxFilterConfig,
      sourceFrom: 'sideFilter',
    })
  })
}

const onTspChange = ({ tspData, operation, curSelectedItem, curSelectedNum }) => {
  const tspId = curSelectedNum ? curSelectedItem?.id?.split('_')?.[0] || '' : ''
  emits('saveCurrAttr', tspId, 'tsp')
  let tspIds = tspData.map(item => item.id)

  // 处理自定义筛选中配置tsp
  if (filterBar.value?.filterConfig?.isCccxFilterConfig) {
    const list = menuConfig.value.menuItemsConfig
      .filter(item => item.type == 'multi')
      .reduce((acc, item) => {
        const childList = item.data.data.reduce((acc2, cur2) => {
          return acc2.concat(cur2.data?.filter(obj => obj.active && obj.nodeType == 7 && !tspIds.includes(`${obj.id}_${obj.mix_value}`)) || [])
        }, [])

        return acc.concat(childList)
      }, [])
    tspIds = tspIds.concat(list.map(item => `${item.id}_${item.mix_value}`))
  }

  operation != 'reset' && analysis.clickTsps({ tsps: tspIds, clickType: `Filter` })

  emits('change', {
    type: 'tsp',
    rightFilterFalg: true,
    params: {
      tsp_ids: tspIds.join(','),
      ...getSourceInsideFilterOrCloudTagPanel({ sourceFrom: 'sideFilter', active: curSelectedItem.active })
    }
  })
}

const onSidePanelChange = (params) => {
  if(params?.dataType == 'shopType') return onShopTypeChange(params)
  if (params?.dataType == 'cate') return onCategoryChange(params)
  if (params?.type === 'price') return onPriceChange(params)
  if (['multi', 'img'].includes(params?.type)) return onAttrChange(params)
  if (params?.type == 'tsp') return onTspChange(params)

  emits('change', {
    params,
    type: 'other',
    rightFilterFalg: true,
  })
}

defineExpose({ openSideFilter })
</script>

<style scoped lang="less">
.nav-bar {
  &__filter {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  &__filter-label {
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #666;
  }

  &__filter-label-highlight {
    font-weight: 700;
    color: var(@sui_color_gray_dark1, #222);
  }

  &__filter-filter-num{
    position: absolute;
    top: 12px;
    right: 0;
    width: 13px;
    height: 13px;
    line-height: 13px;
    text-align: center;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    font-size: 12px;
    z-index: @zindex-hack;
    font-weight: normal;
  }
}
</style>
