
import { ref, watch } from 'vue'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

export function usePriceTags ({ filterBar, language, queryParams }) {
  const list = ref([])

  watch(filterBar, () => {
    // 不外露price
    if (!filterBar.value?.filterConfig?.tagCloudSlotConfig?.find(item => item.tagId == 14003)) {
      list.value = []
      return
    }

    const filterPrices = filterBar.value?.filterData?.filterPrices || {}
    const { min_price, max_price } = filterPrices
    const { min_price: startMinPrice, max_price: startMaxPrice } = queryParams.value || {}
    const config = (filterBar.value?.filterConfig?.tagCloudSlotConfig || []).find(item => item.tagId == 14003)
    const selectedNum = (queryParams.value.max_price || queryParams.value.min_price) ? 1 : 0
    const labelConfig =  config?.displayContent?.labelLang || config?.tagName || '' // ccc配置文案
    const labelLanguage = language.value?.SHEIN_KEY_PWA_16527 // 多语言文案
    const label = labelConfig 
      ? (selectedNum ? `${labelConfig}(${selectedNum})` : labelConfig)
      : (selectedNum ? `${labelLanguage}(${selectedNum})` : labelLanguage)

    list.value = [{
      data: filterPrices,
      value: 'price',
      label,
      isInput: true,
      selectedNum,
      tagItemType: TAG_ITEM_TYPE.PRICES,
      range: {
        isInput: true,
        maxPrice: max_price,
        minPrice: min_price,
        startMaxPrice,
        startMinPrice,
        language: {
          to: language.value?.SHEIN_KEY_PWA_27244,
          min: language.value?.SHEIN_KEY_PWA_27242,
          max: language.value?.SHEIN_KEY_PWA_27243,
        },
      }
    }]
  }, { immediate: true })

  return list
}
