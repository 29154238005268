<template>
  <div 
    :class="{
      'nav-bar__button': true,
      'nav-bar__button-active': active,
    }"
    role="tab"
    :aria-label="label"
    @click="onClick"
  >
    <span class="nav-bar__label">
      {{ label }}
    </span>

    <i
      v-if="icon"
      :class="[
        icon,
        'suiiconfont',
        'nav-bar__button-icon'
      ]"
    ></i>
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { getClickType, analysisClickSort } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'


const emits = defineEmits(['click', 'change'])
const props = defineProps({
  value: { type: [String, Number], default: '' },
  icon: { type: String, default: '' },
  label: { type: String, default: '' },
  active: { type: Boolean, default: false },
})

const sortMap = inject('sortMap', [])
const topTwoList = inject('topTwoList', [])
const isConfigAutoSticky = inject('isConfigAutoSticky', false) 

const toStickyByClick = () => emits('toStickyByClick')

const onClick = () => {
  const sort = props.value
  emitCloseDropdown()
  isConfigAutoSticky.value && toStickyByClick()
  analysisClickSort({
    sort: props.value,
    sortType: props.label,
    clickType: getClickType(unrefs({ sort, sortMap, topTwoList })),
  })
  emits('change', { params: { sort } })
}
</script>

<style scoped lang="less">
.nav-bar {
  &__button {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: 3.36rem;
    padding-right: 12px;
  }

  &__button-active {
    font-weight: 700;
    color: #222;
    font-weight: 600;
    color: var(@sui_color_gray_dark1, #000);
  }

  &__label{
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }

  &__button-icon {
    font-size: 14px;
    margin-left: 4px;
  }
}
</style>
