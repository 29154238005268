<template>
  <div
    :id="id"
    ref="stickyEl"
    class="scroll-sticky"
    :class="className"
    :style="myStyles"
    :data-z-index="zIndex"
    :data-sticky-height="stickyHeight"
    :data-is-config-sticky="String(isConfigSticky)"
    :data-is-sticky-status="String(isStickyStatus)"
    :data-is-scroll-down="String(isScrollDown)"
    :data-sticky-position="stickyPosition"
    :data-sticky-top="top"
    :data-scroll-diff="stickyDiff"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, onBeforeUnmount, onActivated, onDeactivated } from 'vue'
import { useEventListener } from '../hooks/useEventListener'
import { throttle, debounce } from '@shein/common-function'
import { HEADER_HEIGHT } from 'public/src/pages/components/FilterBar/utils/const'
import {
  isDisabledScroll,
  setDisabledScroll,
  // eslint-disable-next-line no-unused-vars
  cancelDisabledScroll
} from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'
import { useBodyOverflowWatcher } from 'public/src/pages/components/FilterBar/hooks/useBodyOverflowWatcher'
import {
  emitUpdateDropdownPanelHeight,
  emitCloseKidTagPopover
} from '../eventCenter'
import {
  sleep,
  getIsLock
} from 'public/src/pages/components/FilterBar/utils/index.js'

const emits = defineEmits(['stickyChange', 'smallChange'])
const props = defineProps({
  id: { type: String, default: undefined },
  className: { type: String, default: '' },
  top: { type: Number, default: -1000000 },
  styles: { type: Object, default: () => ({}) },
  translageY: { type: Number, default: 0 }, // 下滑时的位移，露出header
  rootMarginTop: { type: Number, default: 0 }, // IntersectionObserver使用
  zIndex: { type: Number, default: 3 },
  stickyHeight: { type: Number, default: 0 }, // 吸顶后dom高度
  isConfigSticky: { type: Boolean, default: false }, // 是否配置了sticky,如abt配的标签吸顶，则不需要吸顶
  isStickyStatus: { type: Boolean, default: false }, // 当前是否吸顶状态
  isConfigSmall: { type: Boolean, default: false }, // PicTopNav有小图模式，传true，其他NavBar/Cloudtags为false
  isSmallStatus: { type: Boolean, default: false }, // 当前是否小图模式
  smallHeightDiff: { type: Number, default: 0 }, // PicTopNav使用图文下大小图模式下的高度差，计算滚动时候大小图切换临界点
  isScrollDown: { type: Boolean, default: false }, // 是否下滑
  loading: { type: Boolean, default: false },
  stickyDiff: { type: Number, default: 0 }, // 设置吸顶时候需要加上的滚动距离,如picks下，图文&CloudTags吸顶时，图文吸顶距离需要加上NavBar高度
  isTransform: { type: Boolean, default: true }, // 是否需要transform, 一键购引导弹窗不需要transform，避免fixed失效
  stickyPosition: { type: String, default: '' }, // 是否使用position: sticky
  opacityTransition: { type: Boolean, default: false } // 是否需要透明度变化
})

const {
  top,
  zIndex,
  loading,
  isConfigSmall,
  isConfigSticky,
  isStickyStatus,
  isSmallStatus,
  isScrollDown,
  translageY,
  stickyDiff
} = toRefs(props)

const opacity = ref(1)

const placeholder = ref(null)

let isScrollTicking = false

const myStyles = computed(() => {
  return {
    transition: 'all 0.3s',
    ...props.styles,
    // top: `${top.value + (isScrollDown.value ? translageY.value : 0)}px`,
    top: `${top.value}px`,
    zIndex: zIndex.value,
    transform: props.isTransform
      ? `translate3d(0, 0, ${zIndex.value}px)`
      : null,
    opacity: opacity.value
  }
})

const STYLE_NUMBER = {
  CARD_MARGIN_Y: 8 + 10, // 图文导航卡片上下间距 
  STICKY_HIGHT: 48, // 吸顶高度
  HEADER_HEIGHT // 顶部导航栏高度
}

// sticky & scroll
const stickyEl = ref(null)
const lastScrollTop = ref(0)
const noStickyHight = ref(0) // 非吸顶高度
const position = computed(() => {
  return props.stickyPosition || 'sticky'
})



// vdom [ios下getBoundingClientRect取值异常，是否吸顶改用vdom判断]
const vDom = ref()
const insertVDom = () => {
  const stickyDom = stickyEl.value
  const pDom = stickyDom.parentNode
  const dom = document.createElement('div')
  dom.classList.add('scroll-sticky__vdom')

  vDom.value = dom
  pDom.insertBefore(dom, stickyDom)
}
const removeVDom = () => vDom.value?.remove()
// 改变非sticky布局的高度
const changeNoStickyHight = () => {
  const CARD_EL = stickyEl.value
  const CARD_HEIGHT = CARD_EL.getBoundingClientRect().height // 图文导航卡片高度
  if (CARD_HEIGHT > 60) {
    noStickyHight.value = CARD_EL.getBoundingClientRect().height // 图文导航卡片高度 可能会变更 所以每次都要取 小于60就是吸顶 不更新
  }
}
const { isBodyOverflowHidden, bodyOverflowHiddenScrollTop } = useBodyOverflowWatcher()
onMounted(() => {
  insertVDom()
  appEventCenter.$on('changePicTopNavHight', () => {
    changeNoStickyHight()
    handlePlaceholder()
  })
})

onActivated(() => {
  changeNoStickyHight()
  handlePlaceholder()
})

onDeactivated(() => {
  changeNoStickyHight()
})

onBeforeUnmount(() => {
  removeVDom()
  appEventCenter.$off('changePicTopNavHight')
})

// 占位
const handlePlaceholder = (flag, isSticky = false) => {
  
  // 如果不需要透明度变化，不需要占位
  if (!props.opacityTransition) {
    // 如果已经有了占位元素，隐藏
    if (placeholder.value) {
      placeholder.value.classList.add('mshe-none')
    }
    return
  }

  // 在stickyEl元素的前面插入一个占位元素，用于占位
  if (!placeholder.value) {
    placeholder.value = document.createElement('div')
    placeholder.value.classList.add('scroll-sticky__placeholder')
    stickyEl.value.insertAdjacentElement('afterend', placeholder.value)
  }
  placeholder.value.style.height =
      isSticky ? noStickyHight.value + STYLE_NUMBER.CARD_MARGIN_Y - STYLE_NUMBER.STICKY_HIGHT + 'px' : noStickyHight.value + STYLE_NUMBER.CARD_MARGIN_Y + 'px'
  if (flag) {
    placeholder.value.classList.remove('mshe-none')
  } else {
    placeholder.value.classList.add('mshe-none')
  }
}

const changePositionAndOpacity = nowScrollTop => {
  if (!isConfigSticky.value) {
    return
  }
  // 滚动时一般不更新高度
  throttle({
    func: changeNoStickyHight,
    wait: 1000
  })()
  const transparentBoundary =
    noStickyHight.value +
    STYLE_NUMBER.CARD_MARGIN_Y -
    2 * STYLE_NUMBER.HEADER_HEIGHT
  if (nowScrollTop < transparentBoundary) {
    emits('update:isStickyStatus', false)
    emits('update:stickyPosition', 'initial')
    emits('update:isSmallStatus', false)
    handlePlaceholder(false)
  } else if (nowScrollTop < transparentBoundary + STYLE_NUMBER.HEADER_HEIGHT + STYLE_NUMBER.STICKY_HIGHT) {
    emits('update:isStickyStatus', true)
    emits('update:stickyPosition', 'fixed') // 过度动画 需要fixed布局
    emits('update:isSmallStatus', true)
    handlePlaceholder(true)
  } else {
    emits('update:isStickyStatus', true)
    emits('update:stickyPosition', 'sticky') // 最终会改为sticky布局 与其他场景保持一致
    emits('update:isSmallStatus', true)
    handlePlaceholder(true, true)
  }

  
  if (nowScrollTop < STYLE_NUMBER.HEADER_HEIGHT) {
    // 滚动距离小于头部高度时 不透明
    opacity.value = 1
  } else if (nowScrollTop < transparentBoundary) {
    // 滚动距离小于透明边界值时 透明度逐渐减少至0
    opacity.value = 1 - nowScrollTop / transparentBoundary
  } else if (nowScrollTop < transparentBoundary + STYLE_NUMBER.STICKY_HIGHT) {
    // 滚动距离小于透明 + 吸顶边界值时 透明度逐渐增加至1
    opacity.value =
      (nowScrollTop - transparentBoundary) / STYLE_NUMBER.STICKY_HIGHT
  } else if (nowScrollTop > transparentBoundary + STYLE_NUMBER.STICKY_HIGHT) {
    // 滚动距离大于透明 + 吸顶边界值时 透明度为1
    opacity.value = 1
  } else {
    opacity.value = 1
  }
}

// 滚动时执行节流
const handlePositionAndOpacityThrottle = throttle({
  func: changePositionAndOpacity
})
// 滚动结束时兜底一次 300s 避免滚动太快
const handlePositionAndOpacityDebounce = debounce({
  func: changePositionAndOpacity,
  wait: 300
})

const handlePositionAndOpacity = nowScrollTop => {
  if (isBodyOverflowHidden.value) {
    nowScrollTop = bodyOverflowHiddenScrollTop.value
    const delta = nowScrollTop - lastScrollTop.value
    if (Math.abs(delta) < 1 || isDisabledScroll()) return // 计算误差，如取消遮罩层时候的误差
  }
  
  if (window?.requestAnimationFrame) {
    // 如果支持requestAnimationFrame，使用requestAnimationFrame
    if (!isScrollTicking) {
      isScrollTicking = true
      requestAnimationFrame(() => {
        changePositionAndOpacity(nowScrollTop)
        isScrollTicking = false
      })
    }
  } else {
    // 不支持requestAnimationFrame，使用节流， 避免每次scroll触发都执行计算
    handlePositionAndOpacityThrottle(nowScrollTop)
  }
  // 避免滚动太快 做一个1s的延迟执行
  handlePositionAndOpacityDebounce(nowScrollTop)
}

// 处理图文非吸顶下切换小图
function handlePicTopNavToSmall(nowScrollTop) {
  if (
    !isConfigSmall.value 
    || isDisabledScroll() 
    || props.id !== 'scroll-top-pic-top-nav' 
    || isConfigSticky.value
  ) return
  
  if (nowScrollTop < 1) {
    isConfigSmall.value && !window.isBodyFixed && emits('update:isSmallStatus', false)
    return
  }

  const domSticky = vDom.value
  const domHeader = document.querySelector('.j-common-header-dom')
  if (!domSticky || !domHeader) return

  const rectSticky = domSticky.getBoundingClientRect()
  const rectHeader = domHeader.getBoundingClientRect()
  const diff = rectHeader.bottom - rectSticky.top
  if (isSmallStatus.value) {
    emits('update:isSmallStatus', nowScrollTop < 1 || diff > 1)
  } else {
    emits('update:isSmallStatus', rectSticky.top < 0 || diff > 40)
  }
}

// scroll
useEventListener('scroll', async () => {
  emitCloseKidTagPopover()
  const nowScrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  

  handlePicTopNavToSmall(nowScrollTop)
  if (props.opacityTransition) {
    handlePositionAndOpacity(nowScrollTop)
    return
  }

  const delta = nowScrollTop - lastScrollTop.value
  if (Math.abs(delta) < 1 || isDisabledScroll()) return // 计算误差，如取消遮罩层时候的误差
  lastScrollTop.value = nowScrollTop
  const scrollDown = delta < 0
  if (scrollDown && !isBodyFixed && nowScrollTop < 1) {
    emits('update:isStickyStatus', false) // 当前滚动距离小于1时，设置为不吸顶
    isConfigSmall.value && emits('update:isSmallStatus', false)
    return
  }
  // isScrollDown.value !== scrollDown && cancelDisabledScroll() // 避免切换滚动方向时候，无法响应【上滑切换小图，马上下滑，可能存在无法恢复大图】

  if (
    getIsLock() ||
    isDisabledScroll() ||
    window.isBodyFixed ||
    loading.value ||
    !isConfigSticky.value
  )
    return

  emits('update:isScrollDown', scrollDown)

  // 判断是否不吸顶
  const stickyDom = vDom.value
  const stickyRect = stickyDom?.getBoundingClientRect()
  if (nowScrollTop < 1) {
    emits('update:isStickyStatus', false) // 当前滚动距离小于1时，设置为不吸顶
    isConfigSmall.value && emits('update:isSmallStatus', false)
  } else if (stickyRect.top - top.value < 1) {
    emits('update:isStickyStatus', true)
  } else if (stickyRect.top - (top.value + translageY.value) > 1) {
    emits('update:isStickyStatus', false)
  }

  if (!isStickyStatus.value) {
    isConfigSmall.value && emits('update:isSmallStatus', false)
    return
  }

  

  // 不需要小图模式或者吸顶时候不需要走下面逻辑
  if (!isConfigSmall.value || (isStickyStatus.value && isSmallStatus.value))
    return

  let flag = false
  const nextElementSibling = stickyEl.value?.nextElementSibling
  // picks下图文&navbar/cloudtags都会吸顶
  if (nextElementSibling?.dataset?.isStickyStatus === 'true') {
    flag = true
  } else {
    const rect = stickyEl.value?.getBoundingClientRect()
    const nextRect = nextElementSibling.getBoundingClientRect()
    flag = rect.top + rect.height - nextRect.top >= 40
  }

  flag && setDisabledScroll()
  emits('update:isSmallStatus', flag)
})

const toSticky = async () => {
  const el = stickyEl.value
  const isLock = getIsLock()
  let computScrollTop = stickyDiff.value - top.value
  computScrollTop += isLock
    ? -parseInt($('body').css('top'))
    : document.documentElement.scrollTop || document.body.scrollTop
  let prev = el.previousElementSibling // 当前元素前一个dom

  if (prev) {
    const rect = el.getBoundingClientRect()
    const rectPrev = prev.getBoundingClientRect()
    const diff = rectPrev.bottom > rect.top ? rectPrev.bottom - rect.top : 0 // (rectPrev.bottom - rect.top)为两个元素之间的间距，如picks页面图文跟NavBar同时吸顶，间距为12px
    computScrollTop += rectPrev.top + rectPrev.height - diff
  } else {
    // 无当前元素上一个dom，需要找到父元素
    const parent = el.parentElement
    const rect = parent.getBoundingClientRect()
    computScrollTop += rect.top
  }
  const appRect = document.querySelector('#app').getBoundingClientRect()
  const isUseComputedScrollTop =
    document.documentElement.clientHeight <
    Math.abs(appRect.top) + appRect.height // 是否使用计算的滚动距离
  const scrollTop = isUseComputedScrollTop ? computScrollTop : 0

  setDisabledScroll() // 吸顶过程避免滚动
  emits('update:isSmallStatus', isUseComputedScrollTop) // 高度不足时，回复大图
  emits('update:isStickyStatus', isUseComputedScrollTop)
  emits('update:isScrollDown', false)

  if (isLock) {
    $('body').css({ top: -scrollTop }).attr('data-offset-top', scrollTop)
    emitUpdateDropdownPanelHeight(100)
    !isUseComputedScrollTop &&
      appEventCenter?.$emit('resetHeaderAppStickyOffset') // 页面高度不足时，重置header fixed状态
    return
  }

  window.scrollTo(0, scrollTop - 1)
  emitUpdateDropdownPanelHeight(300)
  if (!isConfigSmall.value) return
  await sleep(300)
  if (getIsLock()) {
    $('body').css({ top: -scrollTop }).attr('data-offset-top', scrollTop)
  } else {
    window.scrollTo(0, scrollTop)
  }
  emits('update:isSmallStatus', isUseComputedScrollTop)
  emits('update:isStickyStatus', isUseComputedScrollTop)
  emits('update:isScrollDown', false)
  emitUpdateDropdownPanelHeight(300)
}



// 点击标签吸顶，外层调用
const toStickyByClick = () => {
  if (!isConfigSticky.value) return
  // 先注释下面2行，因为有了isStickyStatus.value的判断了
  // const rect = stickyEl.value?.getBoundingClientRect()
  // if (rect.top - top.value < 1) return
  if (isStickyStatus.value) {
    emits('update:isSmallStatus', true)
    emits('update:isStickyStatus', true)
    emits('update:isScrollDown', false)
    emitUpdateDropdownPanelHeight(300)
    window?.appEventCenter?.$emit?.('changeHeaderSticky', true)
    return
  }
  toSticky()
  // 隐藏branch
  const timer = setTimeout(() => {
    clearTimeout(timer)
    window.vBus.$emit('setBranchVisible')
    window?.appEventCenter?.$emit?.('changeHeaderSticky', true)
  }, 200)
}

// 筛选后吸顶，外层调用
const toStickyAfterFilter = () => {
  if (!isConfigSticky.value || !isStickyStatus.value) return
  resetScrollExpand()
  toSticky()
}
const resetSticky = () => {
  isConfigSmall.value && emits('update:isSmallStatus', false)
  emits('update:isStickyStatus', false)
  emits('update:isScrollDown', false)
}

// 重置上滑下滑时候的状态
const resetScrollExpand = () => {
  const dom = stickyEl.value
  if (!dom) return

  Object.entries(myStyles.value).forEach(([key, value]) => {
    dom.style[key] = value
  })
}

defineExpose({
  resetSticky,
  toStickyByClick,
  resetScrollExpand,
  toStickyAfterFilter
})
</script>

<style lang="less" scoped>
.scroll-sticky {
  position: v-bind(position);
}
</style>
