import dayjs from 'dayjs'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'

// 是否存在daily数据
export function isExistDaily (filterBar) {
  return Boolean(Object.keys(filterBar?.filterData || {}).length)
}

export function getDailyList ({ filterBar, language }) {
  const { dailyDates = {} } = filterBar?.filterData || {}
  if (Object.keys(dailyDates).length === 0) return []

  const dates = [{ value: '', label: language?.SHEIN_KEY_PWA_15737 }]
  const time = dayjs(Date.now())
  const today = time.format('YYYY-MM-DD')
  const yesterday = time.add(-1, 'days').format('YYYY-MM-DD')
  if (dailyDates[today]) dailyDates[today] = language?.SHEIN_KEY_PWA_16288 // Today 多语言文案
  if (dailyDates[yesterday]) dailyDates[yesterday] = language?.SHEIN_KEY_PWA_15855 // Yesterday 多语言文案

  for (let key in dailyDates) {
    dates.push({ value: key, label: dailyDates[key] })
  }

  return [{
    data: dates,
    value: 'daily',
    label: language?.SHEIN_KEY_PWA_15190 || '',
    tagItemType: TAG_ITEM_TYPE.DAILY
  }]
}
