import { computed } from 'vue'

import { HEADER_HEIGHT } from 'public/src/pages/components/FilterBar/utils/const'
import { unrefs, getRealPx } from 'public/src/pages/components/FilterBar/utils/index'

// 是否配置navbar吸顶
export function useIsConfigSticky ({ filterBar, picTopNav }) {
  return computed(() => {
    return Boolean(
      filterBar.value?.filterConfig?.isStickyFilter
      || (filterBar.value?.filterConfig?.isStickyPicToNav && picTopNav.length === 0)
    )
  })
  
}

function getStickyConfigByPicks ({ filterBar }) {
  const { isStickyPicksPic, isStickySearch } = filterBar?.filterConfig
  let top = isStickyPicksPic ? 51 : HEADER_HEIGHT
  const height = getRealPx(44)
  top = top + (isStickySearch ? HEADER_HEIGHT : 0)
  return {
    top,
    rootMarginTop: top + height,
    translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移量
    zIndex: 13,
    stickyHeight: height,
  }
}

function getStickyConfigTop (isSticky, isStickySearch) {
  if (!isSticky) return -1000
  
  return isStickySearch ? HEADER_HEIGHT : 0
}

function getStickyConfigZIndex (isSticky, isStickySearch) {
  let zIndex = isStickySearch ? 8 : 12

  return isSticky ? ++zIndex : zIndex
}

export function useStickyConfig ({ catInfo, filterBar, isConfigSticky, isScrollDown, isStickyStatus, dynamicStickyConfig }) {
  return computed(() => {
    if (!isConfigSticky.value) return {} // 非navBar吸顶
    if (catInfo.value?.type === 'picks') return getStickyConfigByPicks(unrefs({ filterBar, isScrollDown, isStickyStatus })) // picks图文特殊处理
    const isStickySearch = filterBar.value?.filterConfig?.isStickySearch
    const { top: dynamicTop = 0 } = dynamicStickyConfig.value || {}
    // 搜索框吸顶异常，getStickyConfigTop与后续(isStickySearch ? HEADER_HEIGHT : 0)重复
    // const top = (dynamicTop || getStickyConfigTop(isConfigSticky.value, isStickySearch)) + (isStickySearch ? HEADER_HEIGHT : 0)
    const top = dynamicTop || getStickyConfigTop(isConfigSticky.value, isStickySearch)
    const height = getRealPx(44)

    return {
      top,
      zIndex: getStickyConfigZIndex(isConfigSticky.value, isStickySearch),
      rootMarginTop: top + height,
      translageY: isStickySearch ? 0 : HEADER_HEIGHT, // 下滑偏移量
      stickyHeight: height,
    }
  })
}
