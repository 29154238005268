/**
 * 搜索图文点击转搜索相关逻辑
 */
import { handleClickSearchFilter, formatSearchItem, handleSearchFilterQuery } from 'public/src/pages/product_list_v2/js/search.common.js'
import { abortRequest } from 'public/src/pre_requests/modules/productList/index.js'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

const PIC_TOP_NAV2_QUERY = 'PIC_TOP_NAV2_QUERY'
// 是否转搜索
export function getIsToQuery ({ catInfo, listAbtResult }) {
  if (catInfo.type !== 'search') return false
  
  return true
}

export function onChangeByToQuery ({ list, route, tabIndex, navIndex, searchFilterWords, instance, extraQuery = {}, listAbtResult }) {
  const item = list[tabIndex]?.navs?.[navIndex] || {}
  formatSearchItem(PIC_TOP_NAV2_QUERY, item)
  if (!(item.filterId && item.filterWord)) return
  
  abortRequest('picTopNav2QueryAbortFetch')

  const query = handleSearchFilterQuery({
    type: PIC_TOP_NAV2_QUERY,
    searchItem: item,
    query: {
      ...route.query,
      ...extraQuery,
    },
    searchFilterWords
  })

  emitCloseDropdown()
  handleClickSearchFilter.bind(instance)(query, PIC_TOP_NAV2_QUERY, listAbtResult)
  
}

// 图文导航过滤
export function  filterPicTopNavByQuery ({ catInfo, listAbtResult, picTopNav }) {
  const { filterAttrIds = '' } = catInfo
  if (!filterAttrIds || !getIsToQuery({ catInfo, listAbtResult }) || !picTopNav?.length) return picTopNav

  const ids = filterAttrIds.split('`').reduce((acc, str) => { 
    const list = str.split(',')
    list[1] && list[2] && acc.push(`${list[1]}_${list[2]}`)

    return acc
  }, [])

  if (!ids.length) return picTopNav

  return picTopNav.reduce((acc, item) => { 
    const navs = item.navs || []

    acc.push({
      ...item,
      navs: navs.filter(obj => obj.nav_id && !ids.includes(obj.nav_id))
    })

    return acc
  }, [])
}
