import { ref, onMounted, onUnmounted } from 'vue'

export function useBodyOverflowWatcher() {
  const isBodyOverflowHidden = ref(false) // 监听body是否overflow:hidden
  const bodyOverflowHiddenScrollTop = ref(0) // 记录body滚动条的位置
  let observer = null
  onMounted(() => {
    observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const bodyOverflow = document.body.style.overflow
          if (bodyOverflow === 'hidden') {
            isBodyOverflowHidden.value = true
            // 去除px等单位 只保留前面的数字
            const positionNumber = document.body.style.top.match(/\d+/)
            bodyOverflowHiddenScrollTop.value = positionNumber ? Number(positionNumber[0]) : 0

          } else {
            isBodyOverflowHidden.value = false
            bodyOverflowHiddenScrollTop.value = 0
          }
        }
      })
    })

    // 配置观察选项
    const config = {
      attributes: true, // 监听属性变化
      attributeFilter: ['style'] // 只监听 style 属性
    }

    // 启动观察
    observer.observe(document.body, config)


  })
  // 在组件卸载时停止观察
  onUnmounted(() => {
    observer.disconnect()
  })
  return {
    isBodyOverflowHidden,
    bodyOverflowHiddenScrollTop
  }
}
