import { computed } from 'vue'

// utils
import { NAV_BAR_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'
import { getIsAllCategoryType } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'
import { isExistDaily } from 'public/src/pages/components/FilterBar/utils/daily.js'
import { isExistTsp } from 'public/src/pages/components/FilterBar/utils/tsp.js'

// 是否存在分类数据
function isExistCategory (filterBar, picTopNav) { 
  if (!filterBar?.filterData?.filterCates?.length) return false
  const isAllCategoryType = getIsAllCategoryType({ picTopNav })
  
  return isAllCategoryType
}

export function useNavBarType ({ filterBar, picTopNav, listAbtResult }) {
  return computed(() => {
    if (filterBar.value?.filterConfig?.isExportFilter) return NAV_BAR_TYPE.SORT_EXPORT

    if (
      isExistDaily(filterBar.value) // daily
      || isExistCategory(filterBar.value, picTopNav.value, listAbtResult.value) // cate
      || isExistTsp(filterBar.value) // tsp
    ) return NAV_BAR_TYPE.ATTR_EXPORT

    return NAV_BAR_TYPE.SORT_QUICK
  })
}
