
import { ref, watch } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getCateList } from 'public/src/pages/components/FilterBar/utils/category.js'
import { FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

export function useCateNavBar ({ catInfo, filterBar, language, listAbtResult, queryParams }) { 
  const list = ref([])
  watch(filterBar, () => {
    list.value = filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getCateList(unrefs({
        catInfo,
        filterBar,
        listAbtResult,
        queryParams,
        language,
        filterBarType: FILTER_BAR_MAP.NAV_BAR
      }))
  }, { immediate: true })

  return list
}

export function useCateTags ({ catInfo, filterBar, language, listAbtResult, queryParams }) {
  var list = ref([])

  watch(filterBar, () => {
    list.value = !filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getCateList(unrefs({
        catInfo,
        filterBar,
        listAbtResult,
        queryParams,
        language,
        filterBarType: FILTER_BAR_MAP.CLOUD_TAGS
      }))
  }, { immediate: true })

  return list
}
