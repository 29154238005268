import { ref, watch } from 'vue'

export default function useFirstIn (catInfo, filterBar) {
  const firstIn = ref(catInfo.value?.requestType === 'firstload' || catInfo.value?.requestType === 'switchTab')

  watch(filterBar, () => {
    firstIn.value = catInfo.value?.requestType === 'firstload' || catInfo.value?.requestType === 'switchTab'
  })

  return firstIn
}
